import React, { useContext } from 'react';
import classnames from 'classnames';

import Context from './Context';
import Header from './Header';
import PrintsGrid from './PrintsGallery';
import PrintsZoomView from '../PrintsGallery';

const Layout = () => {
  const {
    i18n,
    locale,
    bodyRef,
    furnishingsPrints,
    leatherwearPrints,
    toggleCartPrint,
    showZoomedPrint,
    zoomViewPrints,
    zoomedPrintId,
    resetZoomedPrint,
    cartPrintIds,
  } = useContext(Context);

  return (
    <div className={classnames('PrintsPage')}>
      <PrintsZoomView
        i18n={i18n}
        locale={locale}
        prints={zoomViewPrints}
        activePrintId={zoomedPrintId}
        onClose={resetZoomedPrint}
        onPrintAddedToCart={toggleCartPrint}
        cartPrintIds={cartPrintIds}
      />

      <Header />

      <div className="px-5 scrollify">
        <div ref={bodyRef}>
          <div className="container">
            <h4 className="my-5 text-center">
              {i18n.abstract}
            </h4>
          </div>
        </div>

        <PrintsGrid
          i18n={i18n}
          title={i18n.furnishings_title}
          prints={furnishingsPrints}
          cartPrintIds={cartPrintIds}
          onCartButtonClick={toggleCartPrint}
          onZoomButtonClick={showZoomedPrint}
        />

        <div className={classnames('container')}>
          <hr className="my-5" />
        </div>

        <PrintsGrid
          i18n={i18n}
          title={i18n.leatherwear_title}
          prints={leatherwearPrints}
          cartPrintIds={cartPrintIds}
          onCartButtonClick={toggleCartPrint}
          onZoomButtonClick={showZoomedPrint}
        />
      </div>
    </div>
  );
};

Layout.propTypes = {
};

Layout.defaultProps = {
};

export default Layout;
